<template>
  <!-- 小程序/app/web定制开发-->
  <div class="wrapper">
    <Header></Header>
    <div class="body">
      <div class="pic">
        <img :src="page1">
      </div>
      <div class="link">
        <div class="forms1" v-for="(form,index) in forms1" :key="index">
          <div class="form1">
            <div class="form1_icon">
              <img class="form1_img" :src="form.img">
            </div>
            <div class="form1_content">
              <div class="form1_title">{{form.title}}</div>
              <div class="form1_text">{{form.text}}</div>
            </div>
          </div>
        </div>
      </div>
      <Reserve></Reserve>
      <Record></Record>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/header.vue'
import Reserve from '../components/reserve.vue'
import Record from '../components/record.vue'
import Footer from '../components/footer.vue'

export default {
  name: 'page1',
  components: {
    Header,
    Reserve,
    Record,
    Footer
  },
  data () {
    return {
      page1: require('@/assets/img/page1.png'),
      forms1: [{
        img: require('@/assets/img/page1.1.png'),
        title: '1、性价比合理',
        text: '按需定制，专业产品经理人，专业的定制方案，合理的报价'
      }, {
        img: require('@/assets/img/page1.2.png'),
        title: '2、设计高端化',
        text: '优秀的设计团队，多年的设计经验，注重视觉和用户体验'
      }, {
        img: require('@/assets/img/page1.3.png'),
        title: '3、服务一体化',
        text: '从前期规划到后期项目上线，专业的售后团队为您服务'
      }, {
        img: require('@/assets/img/page1.4.png'),
        title: '4、维护平台化',
        text: '专业的技术客服，指导您轻松使用后台，确保APP的顺利运行'
      }, {
        img: require('@/assets/img/page1.5.png'),
        title: '5、实施快速化',
        text: '迅速反应您的需求，整合开发计划，缩短开发周期'
      }, {
        img: require('@/assets/img/page1.6.png'),
        title: '6、售后长期化',
        text: '提供24小时的售后服务，响应迅速，及时解决问题'
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  position: relative;
  z-index: 1;
  margin: 0.42rem -0.08rem 0 -0.08rem;
}
.pic {
  height: 2.02rem;
}
img {
 width: 100%;
}
.link {
  text-align: center;
}
.forms1 {
 margin: 0.16rem 0.16rem  0 0.16rem;
  .form1 {
    position: relative;
    display: flex;
    align-items: center;
    height: 0.7rem;
    margin-bottom: 0.05rem;
    border-radius: 0.08rem;
    box-shadow: 0 0.01rem 0.04rem 0 rgba(0, 0, 0, 0.16);
    .form1_icon {
      margin: 0 0.12rem;
      width: 0.44rem;
      .form1_img {
        width: 100%;
      }
    }
    .form1_content {
      text-align: start;
      margin-right: 0.4rem;
      width: 2.585rem;
      .form1_title {
        margin-bottom: 0.04rem;
        font-family: PingFangSC-Medium;
        font-size: 0.14rem;
      }
      .form1_text {
        font-family: PingFangSC-Regular;
        font-size: 0.12rem;
      }
    }
  }
}
</style>
